import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';

export const initialState = {
    user: {
        logged: false,
        isAdmin: false,
        subscriptionType: SUBSCRIPTION_TYPE.FREE,
        email: null,
        role: null,
        token: null,
        username: null,
        nickname: null,
        isSecurity: false,
        version: null,
    },
    app: {
        width: 0,
        height: 0,
        isMobile: false,
    },
    configuration: {
        isPremium: false,
    },
    competitions: {
        competitions: [],
        fixtures: [],
        shouldUpdate: false,
    },
    content: {
        noticias: [],
        flash: [],
    },
};
