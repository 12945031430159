const environments = {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
};

const currentSeason = 2023;
const currentSeasonStats = 2023;

const ADS_QUERIES_CONSTANTS = {
    DESKTOP_ADS_SIDE_WIDTH: 1800,
    DESKTOP_BANNER_WIDTH: 1050,
    DESKTOP_BANNER_MID_WIDTH: 800,
};

const Breakpoints = {
    XS: 0, // X-Small
    SM: 576, // Small
    MD: 768, // Medium
    LG: 992, // Large
    XL: 1200, // Extra Large
    XXL: 1400, // Extra Extra Large
    XXXL: 1800, // Extra Extra Extra Large
};

const SITE_USER_COOKIE = 'analitica_fantasy';
const PWA_USER_COOKIE = 'analitica_fantasy_pwa';

const defaultUser = 'Analítica Fantasy';
export const BLOCK_CONST = 'af_blockCounter';

export {
    environments,
    currentSeason,
    ADS_QUERIES_CONSTANTS,
    SITE_USER_COOKIE,
    currentSeasonStats,
    Breakpoints,
    defaultUser,
    PWA_USER_COOKIE,
};

export const LOGO_IMAGE = 'https://assets.analiticafantasy.com/quiniela/logos/quiniela-fantasy-logo-x.png';
export const LOGO_IMAGE_TRANSPARENT =
    'https://assets.analiticafantasy.com/quiniela/logos/quiniela-fantasy-logo.png';

export const getLogoImage = (width = 50, height = 50, isTransparent = true) =>
    `${isTransparent ? LOGO_IMAGE_TRANSPARENT : LOGO_IMAGE}?width=${width}&height=${height}`;
