import { initialState } from '../initial-state';
import { REDUCERS } from '@/constants/reducers';
import { RUTAS } from '@/constants/rutas';
import { SITE_USER_COOKIE } from '@/constants/site';
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage } from '@/http/local-storage';

export function user(state, action) {
    switch (action.type) {
        case REDUCERS.LOGGED_IN_USER:
            const jsonData = JSON.stringify(action.payload);
            addToLocalStorage(SITE_USER_COOKIE, jsonData, 30);
            window.location.href = RUTAS.INICIO;
            return { ...state, user: action.payload };
        case REDUCERS.LOGGED_OUT_USER:
            removeFromLocalStorage(SITE_USER_COOKIE);
            window.location.href = RUTAS.INICIO;
            return { ...state, user: initialState };
        case REDUCERS.GET_DATA_FROM_COOKIES:
            const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
            const data = JSON.parse(dataFromCookies);
            const newState = data || initialState.user;

            return { ...state, user: newState };
        default:
            return state;
    }
}
