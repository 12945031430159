import { Button } from '@mui/material';
import { useState, useEffect } from 'react';

const LoginBtn = ({ text, onClick, variant, color, styles, icon, classes, disabled, fullWidth }) => {
    const [animationTrigger, setAnimationTrigger] = useState(false);

    const handleOnClick = (event) => {
        event.preventDefault();
        if (onClick) {
            onClick(event);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationTrigger((prev) => !prev);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Button
            color={color ?? 'primary'}
            variant={variant ?? 'contained'}
            onClick={handleOnClick}
            className={`button-material ${classes} ${animationTrigger ? 'animate' : ''}`}
            style={styles}
            disabled={disabled ?? false}
            fullWidth={fullWidth ?? false}
        >
            {icon}
            {text}
            <style jsx>{`
                .animate {
                    animation: bounce 0.5s;
                }
                @keyframes bounce {
                    0% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(-5px);
                    }
                    100% {
                        transform: translateY(0);
                    }
                }
            `}</style>
        </Button>
    );
};

export default LoginBtn;
