import { REDUCERS } from '@/constants/reducers';

export function competitions(state, action) {
    let competitions = action.payload;
    switch (action.type) {
        case REDUCERS.UPDATE_COMPETITIONS:
            return { ...state, competitions: competitions };
        default:
            return state;
    }
}
