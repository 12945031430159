import { useContext, useEffect } from 'react';
import { REDUCERS } from '@/constants/reducers';
import { Context } from '@/context';
import axiosApiInstance from '@/http/axios-instance';
import URL_FACTORY from '@/http/url-helper';

const LayoutData = () => {
    const { state, dispatch } = useContext(Context);
    const { competitions } = state;

    useEffect(() => {
        axiosApiInstance.post(URL_FACTORY.GetCompetitions, {}).then((response) => {
            dispatch({
                type: REDUCERS.UPDATE_COMPETITIONS,
                payload: {
                    ...response.data,
                    shouldUpdate: competitions.shouldUpdate,
                },
            });
        });

        // setTimeout(() => {
        //     dispatch({
        //         type: REDUCERS.UPDATE_COMPETITIONS,
        //         payload: {
        //             ...competitions,
        //             shouldUpdate: !competitions.shouldUpdate,
        //         },
        //     });
        // }, 1000 * 60 * 5); // every 5 minutes
    }, [competitions.shouldUpdate]);

    return null;
};

export default LayoutData;
