export const COMPETITION_STATUS = {
    Created: 0,
    Published: 1,
    Open: 2,
    InProgress: 3,
    Finished: 4,
    Closed: 5,
};

export const isCompetitionVisible = (status) => {
    return (
        status === COMPETITION_STATUS.Published ||
        status === COMPETITION_STATUS.Open ||
        status === COMPETITION_STATUS.InProgress ||
        status === COMPETITION_STATUS.Finished
    );
};

export const isCompetitionVisibleForCalendar = (status) => {
    return (
        status === COMPETITION_STATUS.Open ||
        status === COMPETITION_STATUS.InProgress ||
        status === COMPETITION_STATUS.Finished ||
        status === COMPETITION_STATUS.Closed
    );
};
