import https from 'https';
import axios from 'axios';
import { getFromLocalStorage } from './local-storage';
import { SITE_USER_COOKIE } from '@/constants/site';
const axiosApiInstance = axios.create();

axiosApiInstance.defaults.httpsAgent = new https.Agent({
    rejectUnauthorized: false,
});

axiosApiInstance.interceptors.request.use(
    async (config) => {
        const userJSON = getFromLocalStorage(SITE_USER_COOKIE);

        if (userJSON) {
            const user = JSON.parse(userJSON);
            const { token, email } = user;

            if (token) {
                config.headers = {
                    Authorization: `Bearer ${token}`,
                    af_email: `${email}`,
                };
            }
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default axiosApiInstance;
