const ReglasDelJuegoIcon = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? '16px'}
            height={height ?? '16px'}
            viewBox="0 0 256 256"
        >
            <path
                fill="#E44332"
                d="M224.002 0H31.998C14.358.04.061 14.337 0 32v192c0 17.6 14.397 32 31.998 32h192.004c17.6 0 31.998-14.4 31.998-32V32c0-17.6-14.397-32-31.998-32"
            />
            <path
                fill="#FFF"
                d="M54.133 120.802c4.463-2.606 100.343-58.325 102.535-59.616c2.192-1.275 2.311-5.196-.16-6.615c-2.454-1.41-7.117-4.089-8.846-5.117a8.03 8.03 0 0 0-7.907.096c-1.227.717-83.151 48.299-85.885 49.86c-3.292 1.882-7.333 1.914-10.6 0L0 74.019v21.583c10.52 6.2 36.718 21.599 43.062 25.225c3.786 2.152 7.413 2.105 11.079-.024"
            />
            <path
                fill="#FFF"
                d="M54.133 161.61c4.463-2.607 100.343-58.326 102.535-59.617c2.192-1.275 2.311-5.196-.16-6.615c-2.454-1.41-7.117-4.089-8.846-5.117a8.03 8.03 0 0 0-7.907.096c-1.227.717-83.151 48.298-85.885 49.86c-3.292 1.882-7.333 1.913-10.6 0L0 114.826v21.583c10.52 6.2 36.718 21.599 43.062 25.225c3.786 2.152 7.413 2.104 11.079-.024"
            />
            <path
                fill="#FFF"
                d="M54.133 204.967c4.463-2.607 100.343-58.326 102.535-59.617c2.192-1.275 2.311-5.196-.16-6.615c-2.454-1.41-7.117-4.088-8.846-5.117a8.03 8.03 0 0 0-7.907.096c-1.227.717-83.151 48.299-85.885 49.86c-3.292 1.882-7.333 1.914-10.6 0L0 158.183v21.583c10.52 6.2 36.718 21.6 43.062 25.225c3.786 2.152 7.413 2.105 11.079-.023"
            />
        </svg>
    );
};

export default ReglasDelJuegoIcon;
