import { useReducer, createContext } from 'react';
import { initialState } from './initial-state';
import { app } from './reducers/app';
import { user } from './reducers/user';
import { competitions } from './reducers/competitions';
import { content } from './reducers/content';

// create context
const Context = createContext({});

// combine reducer function
const combineReducers =
    (...reducers) =>
        (state, action) => {
            for (let i = 0; i < reducers.length; i++) state = reducers[i](state, action);
            return state;
        };

// context provider
const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(combineReducers(app, user, competitions, content), initialState);
    const value = { state, dispatch };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
