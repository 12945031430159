import Link from 'next/link';
import { useContext } from 'react';
import LogoIcon from '../logo-icon';
import CustomImageComponent from '../custom/image';
import { footerLinks, items } from '@/data/navigation-items';
import { getTeamImageById, obtenerEquipos } from '@/data/teams';
import URL_FACTORY from '@/http/url-helper';
import { RUTAS } from '@/constants/rutas';
import { Context } from '@/context';
import { esFechaMayorOIgualActual } from '@/helpers/datetime-helper';

const FooterComponent = () => {
    const currentYear = new Date().getFullYear();
    const { state } = useContext(Context);
    const { competitions } = state;

    const getNavItemsColumn = () => {
        return items
            .filter((x) => !x.admin)
            .map((link, index) => (
                <div className="footer-container__navigation-item" key={index}>
                    <Link className="go-to-see-player-stats" href={link.path}>
                        {link.icon}
                        <span className="li-item ml-5">{link.title}</span>
                    </Link>
                </div>
            ));
    };

    const getFooterLinksColumn = () => {
        return (
            <div className="footer-text-left-first-row-container">
                {footerLinks.map((link, index) => (
                    <Link key={index} className="go-to-see-player-stats" href={link.path}>
                        {link.icon}
                        <span className="li-item ml-5">{link.title}</span>
                    </Link>
                ))}
            </div>
        );
    };

    const getTeamsColumn = () => {
        return obtenerEquipos().map((team, index) => (
            <div className="footer-container__teams-items col-12" style={{ marginBottom: '8px' }} key={index}>
                <Link
                    className="go-to-see-player-stats"
                    href={`${process.env.NEXT_PUBLIC_URL_MASTER_SITE}${RUTAS.EQUIPO}/${team.slug}/alineaciones-probables`}
                >
                    <CustomImageComponent
                        src={getTeamImageById(team.id, 37)}
                        alt={'equipo'}
                        width={25}
                        height={25}
                    />
                    <span className="li-item ml-5">{team.name}</span>
                </Link>
            </div>
        ));
    };

    const getTeamImage = (team) => {
        if (team?.slug) {
            return URL_FACTORY.GetTeamImageLogo(team?.slug, 25);
        }

        return team?.imageUrl;
    };

    const getFixturesColumn = () => {
        const displayFixtures = 10;

        const nextFixtures = competitions.fixtures
            ?.filter((x) => !esFechaMayorOIgualActual(x.date))
            ?.sort((a, b) => new Date(a.date) - new Date(b.date))
            ?.filter((_, i) => i < displayFixtures);

        return nextFixtures?.map((fixture, index) => (
            <Link
                key={index}
                className="go-to-see-player-stats"
                href={`${process.env.NEXT_PUBLIC_URL_MASTER_SITE}${RUTAS.PARTIDO}/${fixture.fixtureId}/alineaciones-probables`}
            >
                <div
                    className="footer-container__fixtures-items col-12"
                    style={{
                        marginTop: '4px',
                        marginBottom: '4px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <CustomImageComponent
                            src={getTeamImage(fixture.home)}
                            alt={'local'}
                            width={25}
                            height={25}
                        />
                        {fixture.home.name}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            gap: '4px',
                            borderBottom: '1px solid rgba(255,255,255,.1)',
                            paddingBottom: '8px',
                        }}
                    >
                        <CustomImageComponent
                            src={getTeamImage(fixture.away)}
                            alt={'visitante'}
                            width={25}
                            height={25}
                        />
                        {fixture.away.name}
                    </div>
                </div>
            </Link>
        ));
    };

    return (
        <footer className="footer-container">
            <div className="container">
                <nav className="row">
                    <div className="col-12 col-lg-6 mb-25">
                        <LogoIcon width={75} />
                        <div
                            className="footer-subtitles"
                            style={{ color: '#fff', lineHeight: '26px', paddingTop: '8px' }}
                        >
                            Información
                        </div>
                        <p
                            style={{
                                color: '#fff',
                                lineHeight: '26px',
                                textWrap: 'balance',
                            }}
                        >
                            <strong>Quiniela Fantasy</strong>: Una quiniela diferente, creada por Analítica
                            Fantasy, donde puedes votar por tus jugadores favoritos y ganar premios usando el
                            sistema tradicional 1X2.
                        </p>
                        <p>
                            Únete a la comunidad de managers y demuestra tus conococimientos de{' '}
                            <strong>fantasy fútbol</strong> y compite por premios contra otros managers.
                        </p>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div style={{ color: '#fff', lineHeight: '26px' }}>
                                    <span className="footer-subtitles">Nuestras páginas</span>
                                </div>
                                <div className="footer-container__navigation-items">
                                    {getNavItemsColumn()}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div style={{ color: '#fff', lineHeight: '26px', paddingTop: '8px' }}>
                                    <span className="footer-subtitles">Conoce Quiniela Fantasy</span>
                                </div>
                                {getFooterLinksColumn()}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-lg-3 mb-25"></div> */}
                    <div className="col-12 col-lg-3 mb-25">
                        <div style={{ color: '#fff', lineHeight: '26px' }}>
                            <span className="footer-subtitles">Posibles onces</span>
                        </div>
                        <div className="row">{getTeamsColumn()}</div>
                    </div>
                    <div className="col-12 col-lg-3 mb-25">
                        <div style={{ color: '#fff', lineHeight: '26px' }}>
                            <span className="footer-subtitles">Alineaciones probables</span>
                        </div>
                        <div className="row">{getFixturesColumn()}</div>
                    </div>
                    {/* <div className="game-predicting-manager">
                        <LogoIcon />
                        <Link
                            href={RUTAS.VOTACIONES}
                            className="footer-subtitles"
                            style={{ marginBottom: '0' }}
                        >
                            Juega a la Quiniela Fantasy
                        </Link>
                    </div> */}
                </nav>
            </div>
            <div className="footer-container__year">Quiniela Fantasy © {currentYear}</div>
        </footer>
    );
};

export default FooterComponent;
