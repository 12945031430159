const URL_FACTORY = {
    GetTeamImageLogo: (teamSlug, customWidth) => {
        const width = customWidth ?? 51;
        if (teamSlug) {
            return `${process.env.NEXT_PUBLIC_IMAGES}/teams/${teamSlug}.png${width ? `?width=${width}` : ''}`;
        }

        return `${process.env.NEXT_PUBLIC_IMAGES}/teams/unknown-teams.png${width ? `?width=${width}` : ''}`;
    },
    GetTeamImage: (teamSlug) => {
        const width = 51;
        if (teamSlug) {
            return `${process.env.NEXT_PUBLIC_IMAGES}/teams/${teamSlug}.png${width ? `?width=${width}` : ''}`;
        }

        return `${process.env.NEXT_PUBLIC_IMAGES}/teams/unknown-teams.png${width ? `?width=${width}` : ''}`;
    },
    LoginUserUrl: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/accounts/login`,
    RegisterUserUrl: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/accounts/register`,
    ResendEmailConfirmation: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/accounts/verify-email`,
    UpdatePassword: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/accounts/update-user-password`,
    GetUserProfile: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/accounts/user-profile`,
    DeleteAccount: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/accounts/delete-account`,
    GetQuinielaConfiguration: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/quiniela-configuration`,
    SaveQuinielaConfiguration: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/quiniela-configuration`,
    GetNoticiasFlash: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/noticias-flash`,
    GetPublishedArticles: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/noticias/noticias-publicadas`,
    GetCompetitions: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/competitions`,
    JoinCompetitions: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/join-competition/${id}`,
    GetClasificacion: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/quiniela-clasificacion`,
    GetFixtures: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/quiniela-fixtures`,
    GetVotacionesManagers: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/votaciones-managers`,
    GetFixtureStatistics: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/fixture-stats/${id}`,
    GetSeoFixture: (fixtureId) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/seo-fixture/${fixtureId}`,
    GetActiveQuiniela: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/quiniela/active`,
    SaveActiveQuiniela: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}/quiniela`,
};

export default URL_FACTORY;
