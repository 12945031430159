import { REDUCERS } from '@/constants/reducers';

export function content(state, action) {
    let content = { ...state.content };
    switch (action.type) {
        case REDUCERS.UPDATE_CONTENT:
            return { ...state, content: { ...content, ...action.payload } };
        default:
            return state;
    }
}
