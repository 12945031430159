import CustomImageComponent from './custom/image';
import { getLogoImage } from '@/constants/site';

const LogoIcon = ({ width }) => {
    const defaultWidth = width ?? 50;
    return (
        <CustomImageComponent
            src={`${getLogoImage(defaultWidth, defaultWidth)}`}
            alt="Quiniela Fantasy"
            width={defaultWidth}
            height={defaultWidth}
        />
    );
};

export default LogoIcon;
