import { useEffect } from 'react';

import { Roboto_Condensed } from 'next/font/google';

import Layout from '@/components/shared/layout';
import '@/styles/base.scss';
import { Provider } from '@/context';

// Configuramos la fuente con los pesos y el subconjunto que necesitamos
const robotoCondensed = Roboto_Condensed({
    subsets: ['latin'],
    weights: ['300', '400', '500', '600'],
    display: 'swap',
});

export default function App({ Component, pageProps }) {
    useEffect(() => {
        // Lógica para eliminar el Service Worker
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    registrations.forEach((registration) => {
                        registration.unregister();
                    });
                });
            });
        }
    }, []);

    return (
        <section className={`analitica-fantasy-site ${robotoCondensed.className}`}>
            <Provider>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </Provider>
        </section>
    );
}
