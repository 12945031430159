import { useContext, useState } from 'react';
import { SwipeableDrawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Link from 'next/link';
import CustomImageComponent from '../custom/image';
import ButtonMaterial from '../custom/button';
import MobileCompetitionComponent from './mobile-competition';
import { items } from '@/data/navigation-items';
import { RUTAS } from '@/constants/rutas';
import { getLogoImage } from '@/constants/site';
import LoginComponent from '@/components/shared/navigation-menu/login';
import { Context } from '@/context';

const DesktopMenuComponent = () => {
    const { state } = useContext(Context);
    const { user } = state;
    const [stateComponent, setStateComponent] = useState({
        left: false,
    });

    const toggleDrawer = () => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setStateComponent({ left: !stateComponent.left });
    };

    const closedDrawer = () => {
        setStateComponent({ left: false });
    };

    return (
        <div className="desktop-menu">
            <div className="desktop-menu__left">
                <ButtonMaterial
                    onClick={toggleDrawer()}
                    ariaLabel="Drawer menu"
                    variant={'none'}
                    styles={{ color: '#fff' }}
                    text={<MenuIcon />}
                />
            </div>
            <div className="container">
                <nav className="desktop-menu__navigation desktop-menu__center ">
                    <Link className="logo-image" href={RUTAS.INICIO}>
                        <CustomImageComponent
                            src={getLogoImage()}
                            alt="Logo de quiniela fantasy"
                            width={50}
                            height={50}
                            className={'desktop-menu__logo'}
                        />
                    </Link>
                    <ul className="desktop-menu__items">
                        {items
                            .filter((x) => !x.admin || user.isAdmin)
                            .map((item) => (
                                <li key={item.id} className="desktop-menu__list-item">
                                    <Link href={item.path} className="desktop-menu__item">
                                        {item.title?.toUpperCase()}
                                    </Link>
                                </li>
                            ))}
                    </ul>
                </nav>
            </div>

            <div className="desktop-menu__right">
                <LoginComponent />
            </div>

            <SwipeableDrawer
                anchor="left"
                open={stateComponent.left}
                onClose={toggleDrawer()}
                onOpen={toggleDrawer()}
                className="mobile-menu-drawer"
            >
                <MobileCompetitionComponent closedDrawer={closedDrawer} />
            </SwipeableDrawer>
        </div>
    );
};

export default DesktopMenuComponent;
