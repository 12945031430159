export const RUTAS = {
    INICIO: '/',
    INICIAR_SESION: '/iniciar-sesion',
    RECUPERAR_CONTRASENA: '/recuperar-contrasena',
    CREAR_CUENTA: '/crear-cuenta',
    QUINIELA_CLASIFICACION: '/quiniela-clasificacion',
    REGLAS_DEL_JUEGO: '/reglas-del-juego',
    VOTACIONES: '/votaciones',
    PERFIL_USUARIO: '/perfil-de-usuario',
    REESTABLECER_CONTRASENA: '/reestablecer-contrasena',
    PERFIL_USUARIO: '/perfil-de-usuario',
    CONFIGURATION: '/configuracion',
    TERMINOS_CONDICIONES: '/terminos-y-condiciones',
    POLITICAS_PRIVACIDAD: '/politicas-de-privacidad',
    NOTICIAS_FLASH: '/noticias-flash',
    CONTACTANOS: '/contactanos',
    NOTICIA: '/noticias',
    CALENDARIO: '/calendario',
    EQUIPO: '/equipos/laliga',
    VOTACIONES_MANAGERS: '/votaciones-managers',
    PARTIDO: '/partido',
};

export const Pages = {
    INICIO: 'INICIO',
    VOTACIONES: 'VOTACIONES',
    VOTACIONES_MANAGERS: 'VOTACIONES_MANAGERS',
    INICIAR_SESION: ' INICIAR_SESION',
    RECUPERAR_CONTRASENA: 'RECUPERAR_CONTRASENA',
    CREAR_CUENTA: 'CREAR_CUENTA',
    QUINIELA_CLASIFICACION: 'QUINIELA_CLASIFICACION',
    PERFIL_USUARIO: 'PERFIL_USUARIO',
    REESTABLECER_CONTRASENA: 'REESTABLECER_CONTRASENA',
    CONFIGURATION: 'CONFIGURATION',
    REGLAS_DEL_JUEGO: 'REGLAS_DEL_JUEGO',
    TERMINOS_CONDICIONES: 'TERMINOS_CONDICIONES',
    POLITICAS_PRIVACIDAD: 'POLITICAS_PRIVACIDAD',
    CONTACTANOS: 'CONTACTANOS',
    NOTICIAS_FLASH: 'NOTICIAS_FLASH',
    NOTICIA: 'NOTICIA',
    CALENDARIO: 'CALENDARIO',
    EQUIPO: 'EQUIPO',
    PARTIDO: 'PARTIDO',
};
