import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { Fragment, useEffect, useContext } from 'react';
import PwaBanner from '../add-to-home-screen/pwa-banner';
import LoadingSpinner from './loading-spinner';
import MobileMenuComponent from './navigation-menu/mobile';
import FooterComponent from './footer';
import DesktopMenuComponent from './navigation-menu/desktop';
import LayoutData from './layout-data';
import BottomNavigationComponent from './footer/navigation';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import { Breakpoints } from '@/constants/site';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#01aa4d',
        },
        disabled: {
            main: '#9e9e9e',
        },
        error: {
            main: '#d32f2f',
        },
        warning: {
            main: '#ed6c02',
        },
        delete: {
            main: '#fff',
            contrastText: '#d32f2f',
        },
        link: {
            main: '#d32f2f',
        },
        tabs: {
            main: '#020c1d',
        },
    },
    typography: {
        allVariants: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            textTransform: 'none',
            fontSize: 16,
        },
    },
});

const Layout = (props) => {
    const { state, dispatch } = useContext(Context);
    const { app, user } = state;

    const handleResize = () => {
        if (typeof window === 'undefined') {
            return;
        }

        let app = { ...state.app };
        app.width = window.innerWidth;
        app.height = window.innerHeight;

        if (window.innerWidth < Breakpoints.LG) {
            app.isMobile = true;
        } else {
            app.isMobile = false;
        }

        dispatch({ type: REDUCERS.UPDATE_APP, payload: app });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
    }, []);

    useEffect(() => {
        dispatch({ type: REDUCERS.GET_DATA_FROM_COOKIES });
    }, [user?.subscriptionType]);

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <LayoutData />

                {app.isMobile ? <MobileMenuComponent /> : <DesktopMenuComponent />}
                {<BottomNavigationComponent />}

                <main className="main-website">{props.children}</main>
                <FooterComponent />
                <ToastContainer />
                <LoadingSpinner isLoading={false}></LoadingSpinner>
                <PwaBanner />
            </ThemeProvider>
        </Fragment>
    );
};

export default Layout;
