import { REDUCERS } from '@/constants/reducers';

export function app(state, action) {
    let app = { ...state.app };
    switch (action.type) {
        case REDUCERS.UPDATE_APP:
            return { ...state, app: { ...app, ...action.payload } };
        default:
            return state;
    }
}
