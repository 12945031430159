import { CircularProgress } from '@mui/material';
import React from 'react';

const LoadingSpinner = ({ isLoading }) => {
    return (
        <div>
            {isLoading && (
                <div className="fade-loader-container">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default LoadingSpinner;
