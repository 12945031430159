
const teams = [
    {
        id: 542,
        short: 'Alavés',
        name: 'Alavés',
        slug: 'd-alaves',
    },
    {
        id: 531,
        short: 'Athletic',
        name: 'Athletic Club',
        slug: 'athletic-club',
    },
    {
        id: 530,
        short: 'Atleti',
        name: 'Atlético de Madrid',
        slug: 'atletico-de-madrid',
    },
    {
        id: 529,
        short: 'Barça',
        name: 'Barcelona',
        slug: 'fc-barcelona',
    },
    {
        id: 538,
        short: 'Celta',
        name: 'Celta de Vigo',
        slug: 'rc-celta',
    },
    {
        id: 540,
        short: 'Espanyol',
        name: 'Espanyol',
        slug: 'rcd-espanyol',
    },
    {
        id: 547,
        short: 'Girona',
        name: 'Girona',
        slug: 'girona-fc',
    },
    {
        id: 546,
        short: 'Getafe',
        name: 'Getafe',
        slug: 'getafe-cf',
    },
    {
        id: 534,
        short: 'Las Palmas',
        name: 'Las Palmas',
        slug: 'ud-las-palmas',
    },
    {
        id: 537,
        short: 'Leganés',
        name: 'Leganés',
        slug: 'cd-leganes',
    },
    {
        id: 798,
        short: 'Mallorca',
        name: 'Mallorca',
        slug: 'rcd-mallorca',
    },
    {
        id: 727,
        short: 'Osasuna',
        name: 'Osasuna',
        slug: 'c-a-osasuna',
    },
    {
        id: 728,
        short: 'Rayo',
        name: 'Rayo Vallecano',
        slug: 'rayo-vallecano',
    },
    {
        id: 548,
        short: 'R.Sociedad',
        name: 'Real Sociedad',
        slug: 'real-sociedad',
    },
    {
        id: 541,
        short: 'R.Madrid',
        name: 'Real Madrid',
        slug: 'real-madrid',
    },
    {
        id: 543,
        short: 'Betis',
        name: 'Real Betis',
        slug: 'real-betis',
    },
    {
        id: 536,
        short: 'Sevilla',
        name: 'Sevilla',
        slug: 'sevilla-fc',
    },
    {
        id: 532,
        short: 'Valencia',
        name: 'Valencia',
        slug: 'valencia-cf',
    },
    {
        id: 720,
        short: 'Valladolid',
        name: 'Valladolid',
        slug: 'valladolid',
    },
    {
        id: 533,
        short: 'Villarreal',
        name: 'Villarreal',
        slug: 'villarreal-cf',
    },
];

export const obtenerEquipos = () => {
    return teams;
}


export const getAllFantasyTeams = () => {
    const newTeams = [...teams];
    newTeams.unshift({ name: 'Todos', slug: 'all', hideImage: true });
    return newTeams;
};

export const getTeamImageById = (teamId, width) => {
    return `${process.env.NEXT_PUBLIC_IMAGES}/equipos/${teamId}.png?width=${width ?? 25}`;
};

export const getTeamUnknown = (width) => {
    return `${process.env.NEXT_PUBLIC_IMAGES}/teams/unknown-teams.png?width=${width ?? 25}`;
};
