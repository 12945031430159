import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { items } from '@/data/navigation-items';

const BottomNavigationComponent = () => {
    const router = useRouter();
    const [active, setActive] = useState(null);

    useEffect(() => {
        setActive(router.pathname);
    }, [router.pathname]);

    const handleMenuClick = (path) => {
        router.push(path);
    };

    return (
        <div className="bottom-navigation">
            <ul className="bottom-navigation__list">
                {items
                    ?.filter((x) => x.isBottom)
                    .map((item) => (
                        <li
                            key={item.id}
                            className={`bottom-navigation__item ${
                                item.path === active ? 'bottom-navigation__item-selected' : ''
                            }`}
                            onClick={() => handleMenuClick(item.path)}
                        >
                            <div className="bottom-navigation__item-content">{item.icon}</div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default BottomNavigationComponent;
