import { useContext, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoginBtn from './login-btn';
import { RUTAS } from '@/constants/rutas';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';

const LoginComponent = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { state, dispatch } = useContext(Context);
    const { user } = state;
    const router = useRouter();

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        dispatch({ type: REDUCERS.LOGGED_OUT_USER });
    };

    const getLoginComponent = () => {
        if (user.logged) {
            return (
                <div>
                    <Button
                        id="nickname"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-label="profile"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <AccountCircleIcon
                            className="icon-profile"
                            style={{ fontSize: '35px', color: '#fff' }}
                        />
                    </Button>
                    <Menu
                        id="profile"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'profile',
                        }}
                    >
                        <Link href={RUTAS.PERFIL_USUARIO}>
                            <MenuItem onClick={handleClose}>
                                <PersonIcon style={{ marginRight: '5px' }} />
                                Perfil de usuario
                            </MenuItem>
                        </Link>
                        <MenuItem onClick={logout}>
                            <ExitToAppIcon style={{ marginRight: '5px' }} />
                            Cerrar sesión
                        </MenuItem>
                    </Menu>
                </div>
            );
        } else {
            return (
                <LoginBtn
                    classes="login__text"
                    text="Iniciar sesión"
                    variant={'none'}
                    onClick={() => router.push(RUTAS.INICIAR_SESION)}
                    styles={{ fontSize: '14px' }}
                    icon={
                        <AccountCircleIcon
                            className="icon-profile"
                            style={{ fontSize: '35px', color: '#fff' }}
                        />
                    }
                />
            );
        }
    };
    return getLoginComponent();
};

export default LoginComponent;
