import * as React from 'react';
import { useRouter } from 'next/router';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useContext } from 'react';
import ReglasDelJuegoIcon from '@/icons/reglas-del-juego';
// import RegaloIcon from '@/icons/regalo-icon';
import { RUTAS } from '@/constants/rutas';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import InfoIcon from '@/icons/info-icon';
import ConfigurationIcon from '@/icons/configuration';

const subMenu = [
    {
        title: 'Reglas',
        icon: <ReglasDelJuegoIcon width={20} height={20} />,
        link: RUTAS.REGLAS_DEL_JUEGO,
    },
    // {
    //     title: 'Premios',
    //     icon: <RegaloIcon />
    // },
];

const SubmenuMobileCompetitionComponent = ({ closedDrawer }) => {
    const [expanded, setExpanded] = React.useState(false);
    const { dispatch, state } = useContext(Context);
    const { user } = state;
    const router = useRouter();

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const logout = () => {
        dispatch({ type: REDUCERS.LOGGED_OUT_USER });
    };

    const handleAction = (action) => {
        if (action === 'logout') {
            logout();
        }
    };

    const handleRedirect = (url) => {
        if (closedDrawer) {
            closedDrawer();
        }

        if (url) {
            router.push(url);
        }
    };

    return (
        <div className="mobile-competition__submenu">
            <Accordion
                expanded={expanded}
                onChange={handleExpansion}
                slots={{ transition: Fade }}
                slotProps={{ transition: { timeout: 400 } }}
                sx={{
                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <InfoIcon />
                        Información y Ayuda
                    </Typography>
                </AccordionSummary>
                {subMenu.map((item, index) => (
                    <AccordionDetails
                        style={{ paddingBottom: '4px' }}
                        key={index}
                        onClick={() => handleAction(item.action)}
                    >
                        {item.link ? (
                            <div
                                style={{ display: 'flex', gap: '8px' }}
                                onClick={() => handleRedirect(item.link)}
                            >
                                <Typography>{item.icon}</Typography>
                                <Typography>{item.title}</Typography>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <Typography>{item.icon}</Typography>
                                <Typography>{item.title}</Typography>
                            </div>
                        )}
                    </AccordionDetails>
                ))}
            </Accordion>
            {user?.isAdmin && (
                <div
                    style={{
                        paddingLeft: '4px',
                        marginBottom: '16px',
                        marginTop: '8px',
                        display: 'flex',
                        gap: '8px',
                    }}
                    onClick={() => handleRedirect(RUTAS.CONFIGURATION)}
                >
                    <ConfigurationIcon width={22} height={22} />
                    <Typography>Configuración</Typography>
                </div>
            )}
            {user.logged ? (
                <div onClick={() => handleAction('logout')} className="mobile-competition__logout">
                    <ExitToAppIcon />
                    <span>Cerrar sesión</span>
                </div>
            ) : null}
        </div>
    );
};

export default SubmenuMobileCompetitionComponent;
