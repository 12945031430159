const HomeIcon = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? '16px'}
            height={height ?? '16px'}
            viewBox="0 0 16 16"
        >
            <g fill="none">
                <path fill="url(#IconifyId193655d0cb457cc7d0)" d="M6 9h4v5H6z" />
                <path
                    fill="url(#IconifyId193655d0cb457cc7d1)"
                    d="M8.687 2.273a1 1 0 0 0-1.374 0l-4.844 4.58A1.5 1.5 0 0 0 2 7.943v4.569a1.5 1.5 0 0 0 1.5 1.5h3v-4a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v4h3a1.5 1.5 0 0 0 1.5-1.5v-4.57a1.5 1.5 0 0 0-.47-1.09z"
                />
                <path
                    fill="url(#IconifyId193655d0cb457cc7d2)"
                    fillRule="evenodd"
                    d="m8.004 2.636l5.731 5.41a.75.75 0 1 0 1.03-1.091L8.86 1.382a1.25 1.25 0 0 0-1.724.007L1.23 7.059a.75.75 0 0 0 1.038 1.082z"
                    clipRule="evenodd"
                />
                <defs>
                    <linearGradient
                        id="IconifyId193655d0cb457cc7d0"
                        x1="8"
                        x2="4.796"
                        y1="9"
                        y2="14.698"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#944600" />
                        <stop offset="1" stopColor="#CD8E02" />
                    </linearGradient>
                    <linearGradient
                        id="IconifyId193655d0cb457cc7d1"
                        x1="3.145"
                        x2="14.93"
                        y1="1.413"
                        y2="10.981"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD394" />
                        <stop offset="1" stopColor="#FFB357" />
                    </linearGradient>
                    <linearGradient
                        id="IconifyId193655d0cb457cc7d2"
                        x1="10.262"
                        x2="6.945"
                        y1="-.696"
                        y2="7.895"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF921F" />
                        <stop offset="1" stopColor="#EB4824" />
                    </linearGradient>
                </defs>
            </g>
        </svg>
    );
};

export default HomeIcon;
