export const getHoursAmPm = (hours) => {
    if (hours < 10 && hours >= 0) {
        return '0' + hours;
    }
    var amPmHours = hours <= 12 ? hours : hours - 12;
    return amPmHours < 10 ? '0' + amPmHours : amPmHours;
};

export const getTimeShortWithMinutes = (date) => {
    if (!date) return '';
    let days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    const dateObject = new Date(date);

    const dayNumber = dateObject.getDay();
    const dayName = days[dayNumber];
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    return `${dayName} ${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month} ${getHoursAmPm(
        hours
    )}:${minutes < 10 ? '0' + minutes : minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
};

export const getDateMMddYYYYHHmm = (dateCSharpFormat) => {
    const date = new Date(Date.parse(dateCSharpFormat));

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}`;
};

export const esFechaMayorOIgualActual = (fecha) => {
    const fechaActual = new Date();
    const fechaProporcionada = new Date(fecha);

    return fechaActual >= fechaProporcionada;
};
