import { RUTAS } from '@/constants/rutas';
import CalendarIcon from '@/icons/calendar-icon';
import ClasificacionIcon from '@/icons/clasificacion';
import ConfigurationIcon from '@/icons/configuration';
import HomeIcon from '@/icons/home';
import ReglasDelJuegoIcon from '@/icons/reglas-del-juego';
import VotacionesIcon from '@/icons/votaciones';

const width = 18;
const height = 18;

export const items = [
    {
        title: 'Inicio',
        path: RUTAS.INICIO,
        id: 'inicio',
        navigate: true,
        icon: <HomeIcon width={width} height={height} />,
        isBottom: true,
    },
    {
        title: 'Votaciones',
        path: RUTAS.VOTACIONES,
        id: 'votaciones',
        navigate: true,
        icon: <VotacionesIcon width={width} height={height} />,
        isBottom: true,
    },
    {
        title: 'Calendario',
        path: RUTAS.CALENDARIO,
        id: 'calendario',
        navigate: true,
        icon: <CalendarIcon iconWidth={width} iconHeight={height} />,
        isBottom: true,
    },
    {
        title: 'Clasificación',
        path: RUTAS.QUINIELA_CLASIFICACION,
        id: 'clasificacion',
        navigate: true,
        icon: <ClasificacionIcon width={width} height={height} />,
        isBottom: true,
    },
    {
        title: 'Reglas',
        path: RUTAS.REGLAS_DEL_JUEGO,
        id: 'reglas',
        navigate: true,
        icon: <ReglasDelJuegoIcon width={width} height={height} />,
        isBottom: true,
    },
    {
        title: 'Configuración',
        path: RUTAS.CONFIGURATION,
        id: 'configuration',
        navigate: true,
        icon: <ConfigurationIcon width={width} height={height} />,
        admin: true,
    },
];

export const footerLinks = [
    {
        title: 'Contáctanos',
        path: RUTAS.CONTACTANOS,
        id: 'contactanos',
        navigate: true,
    },
    {
        title: 'Política de privacidad',
        path: RUTAS.POLITICAS_PRIVACIDAD,
        id: 'politica-de-privacidad',
        navigate: true,
    },
    {
        title: 'Términos y condiciones',
        path: RUTAS.TERMINOS_CONDICIONES,
        id: 'terminos-y-condiciones',
        navigate: true,
    },
    {
        title: '@AnaliticaLaLiga',
        path: 'https://twitter.com/AnaliticaLaLiga',
        id: 'twitter-analiticalfm',
        navigate: true,
    },
];
