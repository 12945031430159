import { useContext } from 'react';
import { useRouter } from 'next/router';
import CustomImageComponent from '../custom/image';
import SubmenuMobileCompetitionComponent from './submenu-mobile-competition';
import { Context } from '@/context';
import LoginIcon from '@/icons/login';
import { RUTAS } from '@/constants/rutas';
import { COMPETITION_STATUS } from '@/constants/competition-status';

const MobileCompetitionComponent = ({ closedDrawer }) => {
    const { state } = useContext(Context);
    const { competitions, user } = state;
    const router = useRouter();

    const handleRedirect = (url) => {
        if (closedDrawer) {
            closedDrawer();
        }

        if (url) {
            router.push(url);
        }
    };

    const getAction = (competition) => {
        switch (competition.status) {
            case COMPETITION_STATUS.InProgress:
                return (
                    <div
                        className="mobile-competition__action-link"
                        onClick={() => handleRedirect(`${RUTAS.VOTACIONES}/${competition.slug}`)}
                    >
                        Votar
                    </div>
                );
            case COMPETITION_STATUS.Closed:
            case COMPETITION_STATUS.Finished:
                return (
                    <div
                        className="mobile-competition__action-link"
                        onClick={() => handleRedirect(`${RUTAS.QUINIELA_CLASIFICACION}/${competition.slug}`)}
                    >
                        Ver clasificación
                    </div>
                );
            case COMPETITION_STATUS.Open:
                return (
                    <div
                        className="mobile-competition__action-link"
                        onClick={() => handleRedirect(`${RUTAS.CALENDARIO}/${competition.slug}`)}
                    >
                        Ver calendario
                    </div>
                );
            case COMPETITION_STATUS.Published:
                return <div>Proximamente</div>;
            default:
                return null;
        }
    };

    return (
        <div className="mobile-competition">
            {user.logged ? (
                <div className="mobile-competition__header">
                    <div className="mobile-competition__logo">
                        <LoginIcon width={50} height={50} />
                    </div>
                    <div className="mobile-competition__user-info">
                        <span className="mobile-competition__user-name">{user.nickname}</span>
                        <span className="mobile-competition__user-email">{user.email}</span>
                        <div
                            className="mobile-competition__user-profile"
                            onClick={() => handleRedirect(RUTAS.PERFIL_USUARIO)}
                        >
                            <span>Ver perfil</span>
                        </div>
                    </div>
                </div>
            ) : null}
            <section className="mobile-competition__body">
                <div className="mobile-competition__container">
                    {competitions.competitions.map((competition, index) => (
                        <div className="mobile-competition__competitions" key={index}>
                            <div className="mobile-competition__competition-left">
                                <CustomImageComponent
                                    src={`${competition.image}?width=105&height=105`}
                                    alt={competition.name}
                                    width={40}
                                    height={40}
                                />
                            </div>
                            <div className="mobile-competition__competition-right">
                                <p className="mobile-competition__competition-name">{competition.name}</p>
                                <p className="mobile-competition__competition-action">
                                    {getAction(competition)}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <SubmenuMobileCompetitionComponent closedDrawer={closedDrawer} />
            </section>
        </div>
    );
};

export default MobileCompetitionComponent;
