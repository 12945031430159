import Image from 'next/image';

const CustomImageComponent = ({ src, width, height, alt, className, style, onClick, unoptimized }) => {
    const customSrc = () => {
        if (!src) return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/0.png`;

        return src;

        // const imageVersion = process.env.NEXT_PUBLIC_IMAGE_VERSION;

        // if (src.includes('?')) {
        //     return src + `&version=${imageVersion}`;
        // } else {
        //     return src + `?version=${imageVersion}`;
        // }
    };

    return (
        <Image
            src={customSrc()}
            width={width}
            height={height}
            alt={alt}
            className={className}
            style={style}
            onError={(e) => console.error(e.target.id)}
            onClick={onClick}
            unoptimized={unoptimized ?? true}
        />
    );
};

export default CustomImageComponent;
