const getFromLocalStorage = (key) => {
    if (typeof localStorage !== 'undefined') {
        const item = localStorage.getItem(key);
        if (item) {
            const parsedItem = JSON.parse(item);
            const { value, expires } = parsedItem;
            if (expires === null || expires >= Date.now()) {
                return value;
            } else {
                localStorage.removeItem(key);
            }
        }
    }
    return null;
};

const removeFromLocalStorage = (key) => {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(key);
    }
};

const addToLocalStorage = (key, value, days) => {
    if (typeof localStorage !== 'undefined') {
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            localStorage.setItem(
                key,
                JSON.stringify({
                    value,
                    expires: date.getTime(),
                })
            );
        } else {
            localStorage.setItem(
                key,
                JSON.stringify({
                    value,
                    expires: null,
                })
            );
        }
    }
};

export { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage };
