export const REDUCERS = {
    SHOW_SPINNER: 'SHOW_SPINNER',
    HIDE_SPINNER: 'HIDE_SPINNER',
    LOGGED_IN_USER: 'LOGGED_IN_USER',
    LOGGED_OUT_USER: 'logged_out_user',
    GET_DATA_FROM_COOKIES: 'get_data_from_cookies',
    UPDATE_APP: 'UPDATE_APP',
    UPDATE_COMPETITIONS: 'UPDATE_COMPETITIONS',
    UPDATE_CONTENT: 'UPDATE_CONTENT',
    UPDATE_FIXTURES: 'UPDATE_FIXTURES',
};
