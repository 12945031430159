import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import ButtonMaterial from '../custom/button';
import CustomImageComponent from '../custom/image';
import MobileCompetitionComponent from './mobile-competition';
import { RUTAS } from '@/constants/rutas';
import LoginComponent from '@/components/shared/navigation-menu/login';
import { getLogoImage } from '@/constants/site';

const MobileMenuComponent = () => {
    const [stateComponent, setStateComponent] = useState({
        left: false,
    });
    const router = useRouter();
    const [centerContent, setCenterContent] = useState(null);

    const toggleDrawer = () => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setStateComponent({ left: !stateComponent.left });
    };

    const closedDrawer = () => {
        setStateComponent({ left: false });
    };

    useEffect(() => {
        switch (router.pathname) {
            case RUTAS.INICIO:
            default:
                setCenterContent(
                    <CustomImageComponent
                        src={getLogoImage(75, 75)}
                        alt="Logo de quiniela fantasy"
                        width={50}
                        height={50}
                        // onClick={toggleDrawer()}
                    />
                );
                break;
            case RUTAS.CALENDARIO:
                setCenterContent(<h1 className="mobile-menu__center">Calendario</h1>);
                break;
            case RUTAS.VOTACIONES:
                setCenterContent(<h1 className="mobile-menu__center">Votaciones</h1>);
                break;
            case RUTAS.QUINIELA_CLASIFICACION:
                setCenterContent(<h1 className="mobile-menu__center">Clasificación</h1>);
                break;
            case RUTAS.REGLAS_DEL_JUEGO:
                setCenterContent(<h1 className="mobile-menu__center">Reglas</h1>);
                break;
        }
    }, [router.pathname]);

    return (
        <div className="mobile-menu">
            <div className="mobile-menu__position">
                <ButtonMaterial
                    onClick={toggleDrawer()}
                    ariaLabel="Drawer menu"
                    variant={'none'}
                    styles={{ color: '#fff' }}
                    text={<MenuIcon />}
                />
            </div>
            <div className="mobile-menu__position mobile-menu__position-center">{centerContent}</div>
            <div className="mobile-menu__position mobile-menu__position-right">
                <LoginComponent />
            </div>
            <SwipeableDrawer
                anchor="left"
                open={stateComponent.left}
                onClose={toggleDrawer()}
                onOpen={toggleDrawer()}
                className="mobile-menu-drawer"
            >
                <MobileCompetitionComponent closedDrawer={closedDrawer} />
            </SwipeableDrawer>
        </div>
    );
};

export default MobileMenuComponent;
